/* NFT Popup Styles */
.nftPopupImageContainer {
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
}

.nftPopupImageContainer ul {
    padding: 0;
}

.nftPopupImage {
    max-height: 400px;
    object-fit: contain;
}

/* About Tab Styles on Popup */
.aboutAccordionHeader {
    background: #d7d7d7 !important;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    min-height: 40px !important;
}

.aboutAccordionHeader > div {
    margin: 0 !important;
}

.aboutInfoContainer {

}

.aboutInfoName {
    font-size: 14px;
    font-weight: bold;
}

.aboutInfoValue {
    font-size: 12px;
}

.aboutAttributeContainer {
    background: linear-gradient(90deg, rgba(159, 103, 187, 0.3) 0%, rgba(202, 61, 118, 0.3) 100%);
    padding: 6px;
    border-radius: 4px;
    text-align: center;
    white-space: pre-wrap;
}

.aboutAttributeName {
    font-size: 14px;
    font-weight: bold;
}

.aboutAttributeValue {
    font-size: 12px;
}

.aboutDescription {
    font-size: 14px;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    z-index: 20;
}

.popup {
    background: #ffffff;
    border-radius: 20px;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
}

@media only screen and (max-width: 850px) {
    .popup {
        width: 90%;
    }
}
@media only screen and (max-width: 520px) {
    .popup {
        padding: 30px 25px;
    }
}
@media only screen and (max-width: 400px) {
    .popup {
        padding: 20px 15px;
        border-radius: 10px;
        display: block;
        top: 10px;
        left: 20px;
        transform: unset;
    }
}
