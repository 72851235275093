.p2pOffer {
  width: 100%;
  margin: 0 auto;
}
.p2pImgContainer {
  display: flex;
  justify-content: center;
  padding: 2rem 0rem;
}
.p2pImg {
  max-width: 25rem;
}
.p2pText{
  text-align: center;
  font-size: 21px;
  padding: 2rem 0rem;

}

.title {
  font-style: italic;
  margin: 0;
  text-align: center;
}
.metamaskCls{
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 120%;
  margin-left: 0.5rem;
  color: #c93f79;
}
/* @media only screen and (max-width: 1600px) {
  .p2pOffer {
    width: 90%;
    margin: 0 auto;
  }
} */
