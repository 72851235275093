.container{

}
.buttonContainer {
  margin-top: 45px;
  text-align: center;
}
.labelContainer {
  display: flex;

  flex-direction: column;
  gap: 0 15px;
  padding: 10px 0;
}
.label {
  display: block;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
.text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #304050;
}
.button {
  display: block;
  border: none;
  outline: none;

  cursor: pointer;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #fff;
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 7px 25px;

  background: linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%);
  border-radius: 4px;
}
.uploadBtn {
  display: flex;
  align-items: center;
}
.fileName {
  margin-left: 0.5rem;
}
.connectWalletMessage {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  margin-left: 0.5rem;
  color: #c93f79;
}
.input {
  display: block;
  width: 100%;
  visibility: hidden;
  position: absolute;
}
