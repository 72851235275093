.mainModalBtn {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #eeeded;
    box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04);
    padding: 15px 38px;
}
.mainModalBtn .btns {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
}
.mainModalBtn .btns .active {
    border: none;
    padding: 8px 20px;
    width: 125px;
    background: linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%);
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    font-family: "Rubik";
}
.mainModalBtn .btns .disableBtn {
    border: none;
    width: 125px;
    padding: 8px 20px;
    background: #dadada;
    color: #a2a1a1;
    border-radius: 4px;
    font-weight: 500;
    font-family: "Rubik";
    cursor: not-allowed;
}
