.container {

}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  z-index: 20;
}

.popup {
  background: #ffffff;
  border-radius: 20px;
  padding: 70px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  width: 500px;
  margin: 0 auto;
}
.formLabel {
  margin-bottom: 4px;
  padding-right: 140px;
  font-weight: bold;
}
.formItem {
  margin-bottom: 8px !important;
  width: 300px;
}
.title {
  text-align: center;
}
.textContainer {
  padding-bottom: 25px;
}
.text {
  text-align: center;
}

@media only screen and (max-width: 850px) {
  .popup {
    width: 90%;
  }
  .formItem {
    width: 300px;
  }
}
@media only screen and (max-width: 520px) {
  .popup {
    padding: 30px 25px;
  }
  .formItem {
    width: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .popup {
    padding: 20px 15px;
    border-radius: 10px;
  }
  .formLabel {
    padding-right: 80px !important;
  }
  .formItem {
    width: 250px;
  }
}
