.letsConnectWrapper {
  /* width: 650px; */
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 50px;
}
.letsConnect {
  display: grid;
  grid-template-columns: 400px 595px;
  gap: 32px;
  min-height: 308px;
  padding: 15px 0;
}
.letsConnect .addressInfo {
  margin-top: 35px;
}
.letsConnect .addressInfo .addressText {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #0d1a26;
}
.descriptionWrapper {
  max-width: 1024px;
  margin: 0 auto;
}
.descriptionWrapper .letsConnectDescription {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #0d1a26;
  margin-bottom: 8px;
}
.letsConnect .addressInfo div span {
  font-size: 18px;
  font-weight: bold;
}
.letsConnect .addressInfo .imgContainer {
  height: 459px;
}
.letsConnect .addressInfo .imgContainer img {
  height: 100%;
}
.calender {
  border: 1px dashed #aeb3b7;
  border-radius: 5px;
}
.map {
  border: 1px solid #aeb3b7;
  border-radius: 5px;
}
.buttonContainer {
  margin-top: 15px;
}
@media only screen and (max-width: 767px) {
  .letsConnectWrapper {
    width: 90%;
  }
  .letsConnect {
    grid-template-columns: 400px;
    min-height: 600px;
  }
}
@media only screen and (max-width: 520px) {
  .letsConnect {
    grid-template-columns: 1fr;
    min-height: 500px;
  }
}
