.wrapper {
  padding: 4px;
}

.dataWrapper {
  /* justify-content: space-between; */
  width: 100%;
  display: grid;
  /* flex-wrap: wrap; */
  justify-content: space-evenly;
  /* flex: 1;
  grid-template-columns: repeat(auto-fit, min-content); */

  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.textContainer {
  display: flex;
  flex-direction: column;

  padding: 0 8px;
  margin: 16px 0;
}
.textContainer:not(:last-child) {
  border-right: 1px solid #eff0f0;
}

.title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: 0.01em;
  color: #0d1a26;
}
.text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.01em;

  color: #808080;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  padding: 0 25px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 500px) {
  .textContainer {
    border: none;
  }
  .textContainer:not(:last-child) {
    border-right: none;
  }
  .textContainerBorder:not(:last-child) {
    border-right: 1px solid #eff0f0 !important;
  }
}
@media only screen and (max-width: 333px) {
  .dataWrapper {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}
