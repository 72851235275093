.container {
  margin: 20px 0;
}
.label {
  display: block;
  padding-bottom: 5px;
  padding-top: 8px;
  cursor: pointer;
  text-transform: capitalize;
}
.photoContainer {
  width: 350px;
  height: 160px;
  border: 1px dashed #aeb3b7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.uploadedImage {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.fileName {
  display: flex;
  align-items: center;
}
.filetext {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #c93f79;
  margin-left: 0.3rem;
}
/* .imageLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.inputFile {
  width: 100%;
  height: 100%;
  display: block;
  visibility: hidden;
} */
.button {
  display: block;
  border: none;
  outline: none;

  cursor: pointer;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #fff;
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 7px 25px;

  background: linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%);
  border-radius: 4px;
}
.input {
  display: block;
  width: 100%;
  visibility: hidden;
  position: absolute;
}
