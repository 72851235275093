.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  z-index: 20;
}

.popup {
  background: #ffffff;
  border-radius: 20px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
}
.dataWrapper {
  padding: 10px 0;
  width: 100%;
}
.title {
  margin: 0;
}
input[type="radio"] {
  height: 1.25rem;
  width: 1.25rem;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  font-size: 22px;
  cursor: pointer;
}
.buttonContainer {
  width: 100%;
  padding-top: 15px;
  display: flex;
  justify-content: center;
}
.estimate {
  margin-bottom: 100px;
}
.periodAndDuration {
  display: flex;
  align-items: center;
  gap: 30px;
}
.labelContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 5px;
}
.label {
  display: block;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
.categoryContainer {
  position: relative;
  border: 2px solid #ccc;
  border-radius: 5px;
  width: 100%;
}
.periodInput {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 2px 0;
}
.dropdownAndIcon {
  padding: 7px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrow {
  font-family: "Rubik";
  font-size: 18px;

  color: #000000;
  cursor: pointer;
  display: block;
}

.categories {
  position: fixed;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1;

  min-width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.categoryItem {
  cursor: pointer;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #304050;
  padding: 12px 24px;
  margin: 0;
}
.categoryItem:hover {
  background: lightgrey;
}
.category {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #304050;
}
.placeholeder {
  opacity: 0.6;
}
.textAlert {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #c93f79;
}

@media only screen and (max-width: 850px) {
  .popup {
    width: 90%;
  }
}
@media only screen and (max-width: 520px) {
  .popup {
    padding: 30px 25px;
  }
}
@media only screen and (max-width: 400px) {
  .popup {
    padding: 20px 15px;
    border-radius: 10px;
    display: block;
    top: 10px;
    left: 20px;
    transform: unset;
  }
}
