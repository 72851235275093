.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  z-index: 20;
}

.popup {
  background: #ffffff;
  border-radius: 20px;
  padding: 70px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
}

.tagline {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.01em;
  color: #808080;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 15px;
  width: 80%;
}
.form {
  width: 100%;
}
.buttonContainer {
  width: 100%;
  display: flex;
  gap: 25px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}
@media only screen and (max-width: 850px) {
  .popup {
    width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .popup {
    padding: 30px 25px;
  }
  .tagline {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .popup {
    padding: 20px 15px;
    border-radius: 10px;
  }
  .buttonContainer {
    gap: 15px;

    margin-top: 30px;
  }
}
