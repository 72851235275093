.inputContainer {
  display: flex;
  flex-direction: column;
  padding: 7px 0;
  width: 100%;
}
.labelWrapper{
  display: flex;
}
.label {
  display: block;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
.text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #304050;
}
.textError{
  color: #c93f79;
}
.input {
  background-color: #fff;
  display: block;
  width: 100%;
  margin: 0 auto;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 7px 12px;
  outline: none;
}
