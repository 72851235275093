.cards {
    outline: 1px solid #dadada;
    border-radius: 24px;
    height: 356px;
    padding: 0 0 20px 0;
    box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04);
}
.cards .cardImage {
    display: flex;
    justify-content: center;
    height: 159px;
}
.cards .cardImage img {
    object-fit: contain;
    object-position: center;
}
.cards .cardTitle {
    margin: 30px 0 0 0;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: "Rubik";
}
.cards .cardPrice {
    margin: 8px 0 0 0;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    font-family: "Rubik";
}
.cards .cardBtn {
    display: flex;
    justify-content: center;
    margin-top: 13px;
}

.cards .cardBtn button {
    border: none;
    padding: 8px 20px;
    background: linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%);
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    font-family: "Rubik";
}
.cardBtn .disabledBtn{
    background: #dadada !important;
    color: #a2a1a1 !important;
}
.metamaskCls {
    font-family: "Rubik";
    font-weight: 400;
    font-size: 12px;
    color: #c93f79;
    text-align: center;
    margin: 10px 0 0 0;
}

@media (max-width: 1199px) {
    .cards {
        height: 342px;
    }
}

@media (max-width: 767px) {
    .cards {
        height: auto;
    }
}
