.wrapper {
  width: 100%;
  padding: 4px;
  margin-top: 20px;
}
.wrapperBorder{
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 20px;
}
.dataWrapper {
  width: 100%;
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  padding: 16px 0;
}

.image {
  margin: 16px 40px;
}

.titleAndIcon {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  border-right: 1px solid #eff0f0;
  border-left: 1px solid #eff0f0;
  padding: 0 40px;
}
.icon {
  display: block;
  width: 70px;
  padding-right: 5px;
}

.title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: 0.01em;
  color: #0d1a26;
}

.text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: green;
  display: block;
  text-decoration: none;
}

.caption {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #808080;
}

@media only screen and (max-width: 500px) {
  .textContainer {
    border: none;
  }
  .textContainer:not(:last-child) {
    border-right: none;
  }
  .titleAndIcon {
    border-right: 1px solid #eff0f0 !important;
    border-left: 1px solid #eff0f0 !important;
  }
}
@media only screen and (max-width: 460px) {
  .dataWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .titleAndIcon {
    border-right: none !important;
    border-left: none !important;
  }
}
