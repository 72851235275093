@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .text-body {
        @apply text-mainColor-800;
        @apply text-opacity-70;
    }
}
.list {
    @apply text-gray-500 space-y-2 ml-4;
}
@layer utilities {
    .list > li::before {
        content: "\2022";
        @apply text-mainColor inline-flex items-center font-bold text-2xl w-4 -ml-4 relative top-[2px];
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
a {
    text-decoration: none;
}
.wrapper {
    width: 600px;
    margin: 0 auto;
    /* padding: 100px 0; */
}
.title {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 150%;
    color: #304050;
    margin-bottom: 15px;
}
.text {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #0d1a26;
}
.Range_rangeStyle__SWTuK {
    transform: scale(1);
    cursor: pointer;
    background: linear-gradient(to right, rgb(223, 101, 101) 0%, rgb(223, 101, 101) 39.3816%, rgb(217, 217, 217) 39.3816%, rgb(217, 217, 217) 100%);
}
.wrapperBorder{
    border: 2px solid black;
    border-radius: 10px;
    margin-top: 20px;
  }
form {
    width: 100%;
}
@media only screen and (max-width: 767px) {
    .wrapper {
        width: 100%;
        margin: 0 auto;
        padding-top: 60px;
    }
}
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    @apply min-h-full  font-body;
}

video {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}

#__next {
    @apply flex flex-col min-h-screen;
}

main a,
.__reakit-portal a {
    @apply border-mainColor-100 relative border-b-2 font-medium;
}

.text-with-gradient-hover:hover,
.text-gradient,
.with-text-gradient > b,
main a:hover,
.__reakit-portal a:hover {
    @apply text-transparent bg-clip-text bg-gradient-to-br from-indigo-400 via-pink-600 to-pink-600;
    --tw-gradient-to: #db2777;
}
.__reakit-portal a:hover,
.text-gradient,
.text-with-gradient-hover:hover,
.with-text-gradient > b,
main a:hover {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
    --tw-gradient-to: #db2777;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.Header {
    @apply bg-white bg-opacity-75 backdrop-filter backdrop-blur-md;

    @apply sticky top-0 z-50;
}

.Header__nav {
    @apply relative mx-auto;
}

.Header__top {
    @apply relative h-16 flex items-center;
}

.Header__menu {
    @apply flex-1 items-center absolute inset-0 mt-16 overflow-auto bg-white;
}

.Header__ul {
    @apply block flex-1 font-medium;
}

.Header__signInButton,
.Header__li > a {
    @apply p-4  border-t border-gray-100;
}

.Header__li > a {
    @apply hover:bg-gray-50 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-br hover:from-indigo-500 hover:via-pink-600 hover:to-pink-700;
}

.Header__signInButton {
    @apply text-center;
}

.Header__burger {
    @apply absolute right-0 inset-y-0 flex items-center mx-4 outline-none focus:outline-none;
}

@screen md {
    .Header {
        @apply sticky top-0 z-50 px-2;
    }

    .Header__nav {
        @apply flex px-0 container;
    }

    .Header__ul {
        @apply inline-flex space-x-10 py-0;
    }
    .Header__li {
        @apply py-0;
    }

    .Header__signInButton,
    .Header__li > a {
        @apply p-0 border-0;
    }

    .Header__signInButton {
        @apply text-right;
    }

    .Header__menu {
        @apply flex  py-0 h-16 static mt-0 overflow-hidden bg-transparent;
    }

    .Header__signInButton {
        @apply p-0 block text-right;
    }

    .Header__burger {
        @apply hidden;
    }
}

@screen lg {
    .Header__ul {
        @apply justify-center items-center;
    }
    .Header__logo,
    .Header__signInButton {
        @apply w-[220px];
    }
}
.nav-tabs {
    margin-top: 50px;
}
.nav-tabs .nav-link {
    color: #000000 !important;
    border-radius: 0 !important;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 500;
    padding: 12px 0;
    
}
@media (max-width: 599px) {
    .nav-tabs .nav-link {
        font-size: 16px;
        padding: 8px 0;
    }
}
.nav-tabs .nav-link.active {
    background-color: #dadada !important;
    border: none !important;
}
@media (max-width: 1023px) {
    .alignment {
        text-align: center;
    }
}
.css-vnkopk-MuiStepLabel-iconContainer {
    padding-right: 0 !important;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: lightgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}