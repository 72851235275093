.claimOffers {
  width: 1100px;
  margin: 0 auto;
}
.tabBtn {
  display: flex;
  justify-content: flex-end;
}
.tabBtn button {
  border: none;
  padding: 8px 25px;
  background: linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%);
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  font-family: "Rubik";
}
.metamaskCls {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 120%;
  margin-left: 0.5rem;
  color: #c93f79;
}
.imgContainer {
  display: flex;
  justify-content: center;
  padding: 2rem 0rem;
}
.placeHolderimg {
  max-width: 25rem;
}
.p2pText {
  text-align: center;
  font-size: 21px;
  padding: 2rem 0rem;
}
@media only screen and (max-width: 1600px) {
  .claimOffers {
    width: 90%;
    margin: 0 auto;
  }
}
