.myRange {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  margin: 0 auto;
}
.rangeWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.rangeStyle {
  height: 18px;
  width: 100%;
  border-radius: 8px;
}

.rangeThumb {
  height: 30px;
  width: 30px;
  left: 8px;

  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  border: 1px solid #c7c7c7;

  border-radius: 50%;

  outline: none;
}
.Range_rangeStyle__SWTuK {
  background: linear-gradient(90deg, #a166b9 1.68%, #c8407b 98.32%);
}
@media only screen and (max-width: 520px) {
  .rangeStyle {
    height: 14px;
  }
  .rangeThumb {
    height: 20px;
    width: 20px;
    left: -3px;
  }
}
