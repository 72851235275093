.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  z-index: 20;
}

.popup {
  background: #ffffff;
  border-radius: 20px;
  padding: 70px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
}
.title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 96%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #0d1a26;
}
.range {
  width: 90%;
  padding: 50px 0;
}
.minMax {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}
.amount {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.01em;
  color: #0d1a26;
}
.tagline {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.01em;
  color: #808080;
  text-align: center;

  width: 80%;
}
.text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.01em;
  color: #808080;
}
.buttonContainer {
  margin-top: 30px;
}
.textAlert {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #c93f79;
}
@media only screen and (max-width: 850px) {
  .popup {
    width: 90%;
  }
}
@media only screen and (max-width: 520px) {
  .title {
    font-size: 22px;
  }
  .popup {
    padding: 30px 25px;
  }
  .tagline {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .popup {
    padding: 20px 15px;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 350px) {
  .title {
    font-size: 18px;
  }
}
