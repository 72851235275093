.dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 170px 0;
    padding-top: 0px;
}
.dashboard2 {
    /* padding-top: 50px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.imgContainer {
    display: flex;
    justify-content: center;
    /* padding: 2rem 0rem; */
}
.placeHolderimg {
    max-width: 25rem;
}
.p2pText {
    text-align: center;
    font-size: 21px;
    padding: 2rem 0rem;
}
.title {
    font-style: italic;
    margin: 0;
}
.text {
    padding: 6px 0;
}
.amount {
    font-weight: 700;
}
.interest {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    align-items: center;
    padding: 50px 0;
}
.dailyInteres {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}
.borderRight {
    border-right: 1px solid #eff0f0;
}
.interestAmount {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 171%;
    letter-spacing: 0.01em;
    color: #808080;
}
.buttonContainer {
    display: flex;
    align-items: center;
    gap: 25px;
    border-bottom: 1px solid #eff0f0;
    padding-bottom: 50px;
}
.textContainer {
    padding: 15px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 700px;
}
.myText {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #0d1a26;
    text-align: left;
    padding: 0 15px;
}
.testing {
    min-height: 15rem;
}
.tabBtn {
    display: flex;
    justify-content: flex-end;
}
.tabBtn button {
    border: none;
    padding: 8px 25px;
    background: linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%);
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    font-family: "Rubik";
}

@media only screen and (max-width: 767px) {
    .dashboard {
        padding: 60px 0;
    }
}
@media only screen and (max-width: 520px) {
    .buttonContainer {
        gap: 15px;
    }
}
@media only screen and (max-width: 575px) {
    .borderRight {
        border: none;
    }
    .interest {
        grid-template-columns: repeat(1, minmax(200px, 1fr));
    }
    .dailyInteres {
        padding: 0 20px;
    }
}
