.wrapper {
  padding-bottom: 100px;
}
.checkBoxContainer {
  padding: 40px 0;
}
.label {
  display: block;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
.categoryContainer {
  position: relative;
  padding: 15px 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 7px 12px;
  margin-bottom: 5px;
  cursor: pointer;
}
.dropdownAndIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrow {
  font-family: "Rubik";
  font-size: 18px;

  color: #000000;
  cursor: pointer;
  display: block;
}
.connectWalletMessage {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #c93f79;
}

.categories {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px 10px;
  position: absolute;
  z-index: 1;

  top: 42px;
  min-width: 101%;
  left: 50%;
  transform: translateX(-50%);
}

.categoryItem {
  cursor: pointer;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #304050;
  padding: 5px 0;
}
.category {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #304050;
}
.placeholeder {
  opacity: 0.6;
}
.photoContainer {
  width: 350px;
  height: 160px;
  border: 1px dashed #aeb3b7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.uploadedImage {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.button {
  display: block;
  border: none;
  outline: none;

  cursor: pointer;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #fff;
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 7px 25px;

  background: linear-gradient(90deg, #9f67bb 0%, #ca3d76 100%);
  border-radius: 4px;
}
.input {
  display: block;
  width: 100%;
  visibility: hidden;
  position: absolute;
}
.fileName {
  display: flex;
  align-items: center;
}
.filetext {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #c93f79;
  margin-left: 0.3rem;
}
/* .imageLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.inputFile {
  width: 100%;
  height: 100%;
  display: block;
  visibility: hidden;
} */

/* @media only screen and (max-width: 400px) {
  .photoContainer {
    width: 100%;
  }
} */
