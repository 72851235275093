.stake {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;
    width: 800px;
    margin: 0 auto;
    z-index: 0;
}

.overlay {
    position: fixed;
    top: 85px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.componentswrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.stakeWrapper {
    max-width: 817px;
    margin: 0 auto;
}

.stakeWrapper .usdcContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 35px;
    max-width: 100%;
    margin: 0 auto;
    padding: 18px;
    border: 1px solid #aeb3b7;
    border-radius: 5px;
}

.usdc {
    display: flex;
    align-items: center;
}

.currency {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
    color: #304050;
    margin-left: 5px;
}

.image {
    width: 40px;
}

.appromaxContainer {
    display: flex;
    flex-direction: column;
}

.appromax {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #808080;
}

.percentage {

}

@media (max-width: 767px) {
    .componentswrapper {
        flex-direction: column;
        gap: 0;
        margin-top: 16px;
    }
}
