.wrapper {
  width: 100%;
  padding: 4px;
  margin-top: 20px;
}
.wrapperBorder{
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 20px;
}
.dataWrapper {
  width: 100%;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}
.textContainer:not(:last-child) {
  border-right: 1px solid #eff0f0;
}
.titleAndIcon {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
.icon {
  display: block;
  width: 70px;
  padding-right: 5px;
}

.title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: 0.01em;
  color: #0d1a26;
}

.text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #808080;
  display: block;
  text-decoration: none;
}

.buttonContainer {
  margin-top: 25px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.refundButtonsContainer {
  display: flex;
  justify-content: center;
  padding: 0 25px;
  margin-bottom: 16px;
}

.sellItemBtn {
  margin-right: 1.2rem;
}

@media only screen and (max-width: 500px) {
  .textContainer {
    border: none;
  }
  .textContainer:not(:last-child) {
    border-right: none;
  }
  .textContainerBorder:not(:last-child) {
    border-right: 1px solid #eff0f0 !important;
  }
}
@media only screen and (max-width: 460px) {
  .dataWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .textContainerBorder:not(:last-child) {
    border-right: none !important;
  }
}
