.heading {
  padding: 40px 0;
  padding-bottom: 20px;
}
.title {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 50px;
}
.tagline {
  font-family: "Rubik", sans-serif;
  font-size: 28px;
}
.heading {
  width: 100%;
  margin: 0 auto;
}
.metamaskCls{
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #c93f79;
}
@media only screen and (max-width: 1050px) {
  .heading {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 575px) {
  .heading {
    margin: 0 auto;
  }
  .title {
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 40px;
  }
  .tagline {
    font-family: "Rubik", sans-serif;
    font-size: 24px;
  }
}
